import { useState } from 'react'

// In the future if there needs to by syncing between browser tabs consider using:
// https://github.com/donavon/use-persisted-state


// Hook
function useLocalStorage<T>(key: string, initialValue: T) {

    const isBrowser = typeof window !== "undefined"

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            if (isBrowser) {
                // Get from local storage by key
                const item = window.localStorage.getItem(key)
                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue
            }
        } catch (error) {
            // If error also return initialValue
            console.error(error)
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T) => {
        try {
            if (isBrowser) {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                          value instanceof Function ? value(storedValue) : value
                // Save state
                setStoredValue(valueToStore)
                // Save to local storage
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error)
        }
    }

    return [storedValue, setValue]
}

export default useLocalStorage
