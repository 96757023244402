import {
    PortableTextRenderer,
    VideoChapter,
    Webinar,
    WebinarPart
}                      from '@open-law/open-law-shared'
import { Duration }    from 'luxon'
import React, {
    FunctionComponent,
    useEffect,
    useRef,
    useState
}                      from 'react'
import ReactPlayer     from 'react-player'
import useLocalStorage from '../../hooks/useLocalStorage'
import YouTubeEmbed    from '../portable-text/YouTubeEmbed'

interface WebinarOverviewProps {
    webinar: Webinar
    partIndex: number
}

const WebinarOverview: FunctionComponent<WebinarOverviewProps> = (props) => {

    const [webinar, setWebinar]           = useState<Webinar>()
    const [videoPartUrl, setVideoPartUrl] = useLocalStorage<string>('videoPartUrl', '')
    const [videoState, setVideoState]     = useLocalStorage<any>(`videoState`, '')
    const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
    const [partIndex, setPartIndex]       = useState<number>(0)

    const videoPlayer = useRef<ReactPlayer>()

    useEffect(() => {
        localStorage.setItem('videoPartUrl', '')
        localStorage.setItem('videoState', '')
        setWebinar(props.webinar)
        setPartIndex(props.partIndex)
        // if (props.webinar && props.partIndex) {
        //     setWebinar(props.webinar)
        //     if (webinar && props.partIndex) {
        //         setVideoPartUrl(webinar.parts[props.partIndex].video.videoUrl)
        //         setVideoState({
        //             videoPosition: 0,
        //             videoUrl: webinar.parts[props.partIndex].video.videoUrl,
        //             ...webinar.parts[props.partIndex]
        //         })
        //         console.log('set video state', videoState, videoPartUrl)
        //     }
        // }
    }, [props.webinar, props.partIndex])


    const handleChangePart = (part: WebinarPart, idx: number) => {
        setPartIndex(idx)
        // console.log('Part: ', part, 'video Part URL: ', videoPartUrl)
        setVideoState({
            ...videoState,
            videoUrl: part.video.videoUrl,
            ...part
        })
    }

    const timeRefToSeconds: (timeRef: string) => number = (timeRef) => {
        const units                                                       = timeRef.split(':')
        let duration: { hours: number, minutes: number, seconds: number } = {hours: 0, minutes: 0, seconds: 0}
        if (units.length === 2) {
            duration.minutes = Number(units[0])
            duration.seconds = Number(units[1])
        } else if (units.length === 3) {
            duration.hours   = Number(units[0])
            duration.minutes = Number(units[1])
            duration.seconds = Number(units[2])
        }
        // console.log(duration)
        return Duration.fromObject(duration)
            .as('seconds')
    }

    const handleVideoPlaying: (playing: boolean) => void = (playing) => setVideoPlaying(playing)

    const handleChangeVideoPosition = (chapter: VideoChapter) => {
        // console.log(videoPlayer)
        handleVideoPlaying(false)
        const videoPosition = timeRefToSeconds(chapter.timeReference)
        setVideoState({
            ...videoState,
            videoPosition
        })
        if (videoPlayer && videoPlayer.current) {
            videoPlayer.current.seekTo(videoPosition)
            handleVideoPlaying(true)
        }
    }

    const renderDividerWithTextCentre = (text: string) => (
        <div className="relative my-4">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"/>
            </div>
            <div className="relative flex justify-start">
                <span className="px-2 bg-white text-sm text-gray-500">
                    {text}
                </span>
            </div>
        </div>
    )

    return (
        <section className="min-h-screen">
            {webinar && (
                <>
                    <div className={'p-8 flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex'}>
                        <div>
                            <nav className="sm:hidden" aria-label="Back">
                                <a href="/webinars"
                                   className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                                    <svg className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    Back
                                </a>
                            </nav>
                            <nav className="hidden sm:flex" aria-label="Breadcrumb">
                                <ol className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <a href="/webinars"
                                               className="text-sm font-medium text-gray-500 hover:text-gray-700">Webinars</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <svg className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                 fill="currentColor"
                                                 aria-hidden="true">
                                                <path fillRule="evenodd"
                                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                            <a href="#"
                                               className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{webinar.title}</a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <section className="flex-grow w-full max-w-7xl mx-auto px-8 lg:flex">
                        <div className={'flex-none min-w-0 bg-white xl:flex'}>
                            <h2 className="text-3xl font-bold leading-7 text-gray-900 sm:text-4xl sm:truncate xs:p-2 mb-8">
                                {webinar.title}
                                {
                                    webinar.authors.length > 0 && (
                                                               <span
                                                                   className={'text-sm leading-6 text-gray-500'}> with {webinar.authors.map((a, idx) => `${a.name}${webinar.authors.length > 1 || idx + 1 !== webinar.authors.length ? ', ' : ''}`)}</span>
                                                           )
                                }
                            </h2>
                        </div>

                    </section>
                    <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
                        <div className="flex-1 min-w-0 bg-white xl:flex">
                            <div
                                className="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-96 xl:border-r xl:border-gray-200 bg-white">
                                <div className="h-full pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                                    <div className="h-full relative">
                                        <div className="inset-0 rounded-lg">
                                            {
                                                webinar.authors
                                                ? webinar.authors.map((author) => (
                                                        <div key={author.name}
                                                             className="flex items-center space-x-4 lg:space-x-6 mb-4">
                                                            <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                                                                 src={author.image.asset.url}
                                                                 alt=""/>
                                                            <div className="font-small text-md leading-6 space-y-1">
                                                                <h4>{author.name}</h4>
                                                                <p className="text-blue-600">{author.jobTitle}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                            <PortableTextRenderer projectColour={'blue'}
                                                                  blocks={webinar._rawWebinarIntroduction}/>
                                            <div className="bg-white overflow-hidden rounded-md">
                                                <h3 className={'font-medium mt-4 underline'}>Parts</h3>
                                                <ul className="divide-y divide-gray-300">
                                                    {
                                                        webinar.parts && webinar.parts.map((part, idx) => part.video && part.video.videoUrl ? (
                                                                              <li key={part._key} className="py-4">
                                                                                  <div className={'mb-2'}>
                                                                                      <button
                                                                                          className={`${idx === partIndex ? 'bg-blue-800 text-white hover:bg-blue-600' : ''} inline-flex w-full items-center px-2.5 py-1.5 border border-transparent rounded font-medium hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                                                                                          onClick={() => handleChangePart(part, idx)}>
                                                                                          <svg className={'h-6 w-6 mr-3'}
                                                                                               xmlns="http://www.w3.org/2000/svg"
                                                                                               fill="none"
                                                                                               viewBox="0 0 24 24" stroke="currentColor">
                                                                                              <path strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    strokeWidth="2"
                                                                                                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                                                                                              <path strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    strokeWidth="2"
                                                                                                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                                                          </svg>
                                                                                          {
                                                                                              part.title.split('\n')
                                                                                                  .join('<br/>')
                                                                                          }
                                                                                      </button>
                                                                                  </div>
                                                                                  {
                                                                                      part.video && partIndex === idx && (
                                                                                                     <ul className={'divide-y  divide-gray-200'}>
                                                                                                         {
                                                                                                             part.video.videoChapters && part.video.videoChapters.map((vc) => (
                                                                                                                                          <li key={vc.chapterDescription}
                                                                                                                                              onClick={() => handleChangeVideoPosition(vc)}
                                                                                                                                              className="py-2 px-6 text-xs text-gray-400 hover:text-black cursor-pointer flex flex-row items-center">
                                                                                                                                              <svg
                                                                                                                                                  className={'flex-shrink-0 h-5 w-5 text-gray-400 mr-4'}
                                                                                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                  fill="none"
                                                                                                                                                  viewBox="0 0 24 24"
                                                                                                                                                  stroke="currentColor">
                                                                                                                                                  <path strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        strokeWidth="2"
                                                                                                                                                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                                                                                                                                                  <path strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        strokeWidth="2"
                                                                                                                                                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                                                                                                              </svg>
                                                                                                                                              {vc.chapterDescription} ({vc.timeReference})
                                                                                                                                          </li>
                                                                                                                                      ))
                                                                                                         }
                                                                                                     </ul>
                                                                                                 )
                                                                                  }
                                                                              </li>
                                                                          ) : null
                                                                      )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                                    <div className="relative h-full">
                                        <h2 className={'text-3xl uppercase font-medium mb-4'}>{webinar.parts[partIndex].title}</h2>
                                        {
                                            webinar.parts[partIndex].video && (
                                                                               <YouTubeEmbed playing={videoPlaying}
                                                                                             url={webinar.parts[partIndex].video.videoUrl}
                                                                                             playerRef={videoPlayer}/>
                                                                           )
                                        }
                                        {renderDividerWithTextCentre('Additional Materials')}
                                        <div
                                            className="portable-text mt-6 prose prose-blue max-w-prose prose-xl text-gray-500 mx-auto">

                                            <PortableTextRenderer projectColour={'blue'}
                                                                  blocks={webinar.parts[partIndex]._rawMaterials}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )

            }
        </section>
    )
}
export default WebinarOverview
